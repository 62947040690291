import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageType } from '../enums/storage.enum';
import { UserService } from '../states/user.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private userService: UserService) {

  }

  get(property: string) {
    const value = localStorage.getItem(property);
    return value;
  }

  set(property: string, value: string | null ){
    if (value !== null){
      if(property ==  StorageType.USER){
        this.userService.updateUser(JSON.parse(value));
      }
      localStorage.setItem(property, value)
    }
    else{
      this.remove(property);
    }
  }

  remove(property: string){
    localStorage.removeItem(property);
  }

  clear(): void{
    localStorage.clear();
  }

  isCandidateAuthenticated(): boolean{
    return this.get('token') !== null && this.get('recruiter') !== null;
  }

}
