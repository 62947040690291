import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user = new BehaviorSubject<any>({});

  updateUser(newUser: any) {
    this.user.next(newUser);
  }

  getUser() {
    return this.user.asObservable();
  }
}
