import { StorageType } from './../enums/storage.enum';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private storageService :StorageService) { }

  isAuthenticated(): boolean {
    const hasSession =  localStorage.getItem('rId') !== null && localStorage.getItem('tkn') !== null && localStorage.getItem('usr') !== null;;
    return hasSession;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  logOut(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  logOutNoRedirect(): void {
    localStorage.clear();
  }

   /**
   *
   * @returns true if the authenticated user is an admin
   */
   isCurrentUserAdmin(){
    const token = this.storageService.get(StorageType.TOKEN);
    const authenticatedUser = JSON.parse(atob(token?.split('.')[1] || '') || '{}');
    return authenticatedUser?.userRol === 'ADMIN' || false;
  }

  /**
   *
   * @returns true if the authenticated user is a super admin
   */
   isCurrentUserRecruiterAdmin(){
    const token = this.storageService.get(StorageType.TOKEN);
    const authenticatedUser = JSON.parse(atob(token?.split('.')[1] || '') || '{}');
    return authenticatedUser?.userRol === 'RECRUITER_ADMIN' || false;
  }

}
